import { observable, computed } from 'mobx';
import { userApi, logoutApi, refreshApi } from '../apis';
import { authorization } from '.';

const SILENT_REFRESH_TIMEOUT_MS = 10000;

class User {
  @observable username: string = '';
  @observable firstName: string = '';
  @observable lastName: string = '';
  @observable email: string = '';
  @observable picture: string = '';
  @observable lastSession: string = '2 hours';
  @observable totalTime: string = '22 hours';

  @computed get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  reset = () => {
    this.username = '';
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.picture = '';
    this.lastSession = '2 hours';
    this.totalTime = '22 hours';
  }
}

export var user = new User();

var silentRefreshTimer:any = -1;

export const refreshUser = async (): Promise<boolean> => {
  try {
    const returnedUser = await userApi.userRead();
    user.username = returnedUser.username || '';
    user.firstName = returnedUser.firstName || '';
    user.lastName = returnedUser.lastName || '';
    user.email = returnedUser.email || '';
    user.picture = returnedUser.picture || '';

    if (silentRefreshTimer === -1) {
      silentRefreshTimer = setInterval(() => {
        refreshApi.refreshCreate({
          data: {}
        })
      }, SILENT_REFRESH_TIMEOUT_MS);
    }
    return true;
  } catch {
    return false;
  }
}

export const logoutUser = async (): Promise<boolean> => {
  try {
    await logoutApi.logoutCreate();

    user.reset();

    if (silentRefreshTimer !== -1) {
      clearInterval(silentRefreshTimer);

      silentRefreshTimer = -1;
    }

    authorization.isAuthorized = false;

    return true;
  } catch {
    return false;
  }
}

export default user;