import React, { useState } from "react";
import ZevFormCard, { SubmitHandler } from "../../components/formcard/FormCard";

import * as yup from "yup";
import {
  Box,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Field } from "formik";
import TextField from "../../components/textfield";
import { passwordApi } from "../../apis";
import { IonPage } from "@ionic/react";
import Content from "../../components/content";
import { useHistory, useParams } from "react-router";

interface Values {
  new_password1: string;
  new_password2: string;
}

interface Params {
  uid: string;
  token: string;
}

const PasswordResetConfirmSchema = yup.object().shape({
  new_password1: yup.string().required(),
  new_password2: yup
    .string()
    .required()
    .test("passwords-match", "Passwords must match", function(value) {
      return this.parent.new_password1 === value;
    }),
});

const PasswordResetConfirm: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();
  const params = useParams<Params>();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onRegister: SubmitHandler<Values> = (
    values,
    { resetForm, setSubmitting, setFieldError }
  ) => {
    passwordApi
      .passwordResetConfirmCreate({
        data: {
          uid: params.uid,
          token: params.token,
          newPassword1: values.new_password1,
          newPassword2: values.new_password2,
        },
      })
      .then(() => {
        resetForm();
        setDialogOpen(true);
      })
      .catch((response) => {
        response
          .json()
          .then((value: any) => {
            if (value.token) {
              setFieldError("new_password2", 'Error.  Reset link possibly has been already used');
            } else if (value.new_password2) {
              setFieldError("new_password2", value.new_password2);
            }
          })
          .catch(() => {
            setFieldError("new_password2", "Network issue");
          });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <IonPage>
      <Content>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <ZevFormCard
            title="Zev Industries Password Reset"
            validationSchema={PasswordResetConfirmSchema}
            width={matches ? "530px" : "300px"}
            showCancel
            onCancel={() => {history.goBack()}}
            submitLabel="Reset"
            submitButtonProps={{
              variant: "outlined",
            }}
            onSubmit={onRegister}
            values={{ new_password1: "", new_password2: "" }}
          >
            <Field
              component={TextField}
              fullWidth
              type="password"
              label="Password"
              name="new_password1"
              required
            />
            <Field
              component={TextField}
              fullWidth
              type="password"
              label="Confirm Password"
              name="new_password2"
              required
            />
          </ZevFormCard>
        </Box>
        <Dialog open={dialogOpen}>
          <DialogTitle>Password Reset Complete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your password has been reset.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
                history.replace('/login');
              }}
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Content>
    </IonPage>
  );
};

export default PasswordResetConfirm;
