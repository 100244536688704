import React from "react";
import { IonPage, useIonViewWillEnter } from "@ionic/react";
import Content from "../../components/content";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { observer } from "mobx-react";
import Header from "../../components/header";
import { NavLink, useRouteMatch } from "react-router-dom";
import Dashboard from "./dashboard";
import Settings from "./settings";
import { refreshUser, user } from "../../stores";
import { Route } from "react-router-dom";

import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";

export interface HomeProps {}

const Home: React.SFC<HomeProps> = observer(() => {
  const theme = useTheme();
  const match = useRouteMatch();
  const onSmallOrGreater = useMediaQuery(theme.breakpoints.up('sm'));

  useIonViewWillEnter(() => {
    refreshUser();
  });

  const drawerContents = !onSmallOrGreater ? (
    <List>
      <Box
        padding={`${theme.spacing(1)}px`}
        display="flex"
        justifyContent="center"
      >
        <Avatar src={user.picture} />
      </Box>
      <ListItem component={NavLink} to={`${match.path}`}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText>Dashboard</ListItemText>
      </ListItem>
      <ListItem component={NavLink} to={`${match.path}/settings`}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText>Settings</ListItemText>
      </ListItem>
    </List>
  ) : undefined;

  const navLinks = onSmallOrGreater ? [
    (<NavLink key={1} to={`${match.path}`}>Dashboard</NavLink>),
    (<NavLink key={2} to={`${match.path}/settings`}>Settings</NavLink>)
  ] : undefined;

  return (
    <IonPage>
      <Content>
        <Header drawerContents={drawerContents} navLinks={navLinks} />

        <Route exact path={`${match.path}`} component={Dashboard} />
        <Route exact path={`${match.path}/settings`} component={Settings} />
      </Content>
    </IonPage>
  );
});

export default Home;
