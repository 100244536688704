import React from "react";
import {
  Box,
  Grid,
  Paper,
  styled,
  Button,
  LinearProgress,
  Avatar,
  useTheme,
} from "@material-ui/core";
import Content from "../../../components/content";

import user from "../../../stores/User";
import { observer } from "mobx-react";

import * as yup from "yup";
import { Form, Formik, Field } from "formik";
import {userApi, passwordApi} from "../../../apis";
import TextField from "../../../components/textfield";

export interface SettingsProps {}

interface ProfileValues {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
}

const ProfileSchema = yup.object().shape({
  username: yup.string().required(),
});

interface PasswordValues {
  newPassword1: string;
  newPassword2: string;
}

const PasswordSchema = yup.object().shape({
  newPassword1: yup.string().required(),
  newPassword2: yup.string().required().test('passwords-match', 'Passwords must match', function(value) {
    return this.parent.newPassword1 === value;
  }),
});

const StyledForm = styled(Form)({
  display: "flex",
  padding: "8px",
  flexDirection: "column",
  justifyContent: "center",
  "& .MuiTextField-root": {
    marginTop: "8px",
    marginBottom: "8px",
  },
});

const Settings: React.SFC<SettingsProps> = observer(() => {
  const theme = useTheme();

  return (
    <Content padding>
      <Box height="80px" />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box padding={`${theme.spacing(1)}px`} display="flex" justifyContent="center">
              <Avatar src={user.picture}/>
            </Box>
            <Formik<ProfileValues>
              initialValues={{
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
              }}
              validationSchema={ProfileSchema}
              onSubmit={(values, { setSubmitting, setFieldError }) => {
                userApi
                  .userUpdate({
                    data: {
                      username: values.username,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      country: 'US'
                    },
                  })
                  .then((response) => {
                    user.firstName = response.firstName || '';
                    user.lastName = response.lastName || '';
                    user.username = response.username || '';
                  })
                  .catch((response: any) => {
                    try {
                      response.json().then((value: any) => {
                        setFieldError("email", value.non_field_errors);
                      });
                    } catch {
                      setFieldError("email", "Network error");
                    }
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
              render={({
                submitForm,
                isSubmitting,
                values,
                dirty,
                isValid,
              }) => (
                <StyledForm>
                  <Field
                    component={TextField}
                    fullWidth
                    type="username"
                    label="Username"
                    name="username"
                    required
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    label="First Name"
                    name="firstName"
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    label="Last Name"
                    name="lastName"
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    label="Email"
                    name="email"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Box height="8px" />
                  {isSubmitting && <LinearProgress />}
                  <Box height="8px" />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !dirty || !isValid}
                      onClick={submitForm}
                      type="submit"
                    >
                      Update Profile
                    </Button>
                  </Box>
                </StyledForm>
              )}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper>
          <Formik<PasswordValues>
              initialValues={{
                newPassword1: '',
                newPassword2: '',
              }}
              validationSchema={PasswordSchema}
              onSubmit={(values, { setSubmitting, setFieldError }) => {
                passwordApi
                  .passwordChangeCreate({
                    data: {
                      newPassword1: values.newPassword1,
                      newPassword2: values.newPassword2
                    },
                  })
                  .then((response) => {

                  })
                  .catch((response: any) => {
                    try {
                      response.json().then((value: any) => {
                        setFieldError("newPassword2", value.new_password2);
                      });
                    } catch {
                      setFieldError("password", "Network error");
                    }
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
              render={({
                submitForm,
                isSubmitting,
                values,
                dirty,
                isValid,
              }) => (
                <StyledForm>
                  <Field
                    component={TextField}
                    fullWidth
                    type="password"
                    label="New Password"
                    name="newPassword1"
                    required
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    type="password"
                    label="Confirm Password"
                    name="newPassword2"
                    required
                  />

                  <Box height="8px" />
                  {isSubmitting && <LinearProgress />}
                  <Box height="8px" />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !dirty || !isValid}
                      onClick={submitForm}
                      type="submit"
                    >
                      Update Password
                    </Button>
                  </Box>
                </StyledForm>
              )}
            />

          </Paper>
        </Grid>
      </Grid>
    </Content>
  );
});

export default Settings;
