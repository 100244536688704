import React, { useState } from "react";
import ZevFormCard, { SubmitHandler } from "../../components/formcard/FormCard";

import * as yup from "yup";
import {
  Box,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import { Field } from "formik";
import TextField from "../../components/textfield";
import { passwordApi } from "../../apis";
import { IonPage } from "@ionic/react";
import Content from "../../components/content";
import { useHistory } from "react-router";

interface Values {
  email: string;
}

const RegisterSchema = yup.object().shape({
  email: yup
    .string()
    .required()
    .email(),
});

const PasswordReset: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onRegister: SubmitHandler<Values> = (
    values,
    { resetForm, setSubmitting, setFieldError }
  ) => {
    passwordApi
      .passwordResetCreate({
        data: {
          email: values.email,
        },
      })
      .then(() => {
        resetForm();
        setDialogOpen(true);
      })
      .catch((response) => {
        response
          .json()
          .then((value: any) => {
            console.log(value);
            if (value.email) {
              setFieldError("email", value.email);
            }
          })
          .catch(() => {
            setFieldError("email", "Network issue");
          });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <IonPage>
      <Content>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <ZevFormCard
            title="Zev Industries Password Reset"
            validationSchema={RegisterSchema}
            width={matches ? "530px" : "300px"}
            showCancel
            onCancel={() => {history.goBack()}}
            submitLabel="Send"
            submitButtonProps={{
              variant: "outlined",
            }}
            onSubmit={onRegister}
            values={{ email: "" }}
          >
            <Typography color="textSecondary" variant="body2">Please supply an email address to send password reset instructions to.</Typography>
            <Box height={theme.spacing(2)}/>
            <Field
              component={TextField}
              fullWidth
              type="email"
              label="Email"
              name="email"
              required
            />
          </ZevFormCard>
        </Box>
        <Dialog open={dialogOpen}>
          <DialogTitle>Email Delivered</DialogTitle>
          <DialogContent>
            <DialogContentText>
              An email has been delivered.  Please click on the link in your email to reset your password.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
                history.replace('/login');
              }}
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Content>
    </IonPage>
  );
};

export default PasswordReset;
