import React, { FC } from 'react';

import logo from './logo.png';

export interface LogoProps {
  width: string;
  maxWidth?: string;
}

export const Logo: FC<LogoProps> = ( {width, maxWidth} : LogoProps ) => {
  return (
    <img style={{width: width, maxWidth: maxWidth, height: "auto"}} alt='Zev Logo' src={logo}/>
  );
}

export default Logo;