import React, { FC } from "react";
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  styled,
  IconButton,
  CardActions,
  Box,
  useTheme,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { useHistory } from "react-router";

export interface ZevCardProps {
  /**
   * This is the title that goes in the card header.
   */
  title?: string;

  /**
   * This is the Material typography variant for the title text.
   */
  titleVariant?: "h3" | "h4" | "h5" | "h6";

  /**
   * This is for optional actions to be placed at the start of the footer.
   */
  startActions?: JSX.Element | JSX.Element[];

  /**
   * This is for optional actions to be placed at the end of the footer.
   */
  endActions?: JSX.Element | JSX.Element[];

  /**
   * This is the width to use for the card - CSS string width.  Defaults to the width of the parent.
   */
  width?: string;

  /**
   * Optional navigation linkTo to be put in the header.
   */
  linkTo?: string;

  /**
   * Used with the `styled` pattern.  Must forward through a className.
   */
  className?: string;

  /**
   * Elements to go into the card body.
   */
  children?: any;
}

export const StyledCard = styled(({ width, ...other }: ZevCardProps) => <Card {...other}/>)({
  "&.MuiCard-root": {
    backgroundColor: "#140A30",
    width: (props: ZevCardProps) => props.width,
    marginBottom: "16px",
  },
  "& .MuiCardHeader-root": {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "#828282",
    backgroundColor: "#3B3353",
    color: "#f2f2f2",
    paddingLeft: "18px",
    paddingBottom: "14px",
    paddingTop: "12px",
  },
});

export const ZevCard: FC<ZevCardProps> = ({
  title,
  titleVariant = "h5",
  width = "100%",
  startActions,
  endActions,
  linkTo,
  className,
  children,
}: ZevCardProps) => {
  const history = useHistory();
  const theme = useTheme();

  return (
    <StyledCard width={width} className={className}>
      {title && (<CardHeader
        disableTypography
        action={
          linkTo && <IconButton size="small" onClick={() => {
            history && history.push(linkTo);
          }}>
            <ChevronRight fontSize="large" />
          </IconButton>
        }
        title={<Typography variant={titleVariant}>{title}</Typography>}
      />)}
      <CardContent>
        {children}
      </CardContent>
      {startActions || endActions ? (<CardActions style={{marginBottom:`${theme.spacing(2)}px`}}>
        {startActions}
        <Box flexGrow={1} />
        {endActions}
      </CardActions>) : undefined}
    </StyledCard>
  );
};

export default ZevCard;
