import React from "react";
import { Grid, Box } from "@material-ui/core";
import Content from "../../../components/content";
import { ZevCard } from "../../../components/card";

import { ProfileCard } from "../../../components/dashboardcards";

export interface DashboardProps {}

const Dashboard: React.SFC<DashboardProps> = () => {
  return (
    <Content padding>
      <Grid style={{height: '100%'}} container spacing={3}>
        <Grid alignContent="flex-start" alignItems="flex-start" container item sm={4} xs={12}>
          <ProfileCard />
          <ZevCard title="Current 2020 Season Stats" linkTo="#" />
          <ZevCard title="Friends" linkTo="#" />
        </Grid>
        <Grid style={{flexFlow: 'column'}} container alignContent="flex-start" item sm={8} xs={12}>
          <Grid container spacing={3} item>
            <Grid xs item>
              <ZevCard title="Activity" linkTo="#">
                <Box height="200px"/>
              </ZevCard>
            </Grid>
            <Grid xs item>
              <ZevCard title="Other Thing" linkTo="#">
                <Box height="200px"/>
              </ZevCard>
            </Grid>
            <Grid xs item>
              <ZevCard title="Heart Rate" linkTo="#">
                <Box height="200px"/>
              </ZevCard>
            </Grid>
          </Grid>
          <Grid style={{flexGrow: 1}} container item>
            <ZevCard title="Card 3" />
          </Grid>
        </Grid>
      </Grid>
    </Content>
  );
};

export default Dashboard;
