import React, { FC, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  styled,
  Drawer,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Menu, SettingsOutlined } from "@material-ui/icons";
import Logo from "../logo";
import { logoutUser } from "../../stores";

export interface HeaderProps {
  drawerContents?: any;
  navLinks?: JSX.Element | JSX.Element[];
  title?: string;
}

const StyledAppBar = styled(AppBar)({
  "&.MuiAppBar-root": {
    color: "#000000",
    backgroundColor: "#333333"
  },
  "& #spacer": {
    flexGrow: 1,
  },
  "& a": {
    color: '#f2f2f2',
    marginLeft: '16px',
    marginRight: '16px'
  }
});

export const Header: FC<HeaderProps> = ({
  drawerContents,
  navLinks,
  title = "",
}: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <StyledAppBar color="default" position="static">
        <Toolbar>
          {drawerContents && <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsOpen(!isOpen)}
          >
            <Menu />
          </IconButton>}
          <Logo width="100px" />
          {navLinks}
          <div id="spacer" />
          {title && <Typography variant="h6">{title}</Typography>}
          <Tooltip title="Logout" aria-label="Logout">
            <IconButton edge="start" color="default" aria-label="menu" onClick={async () => {
              await logoutUser();
            }}>
              <SettingsOutlined />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </StyledAppBar>
      <Drawer anchor="left" open={isOpen} onClose={() => setIsOpen(!isOpen)}>
        {drawerContents}
      </Drawer>
    </>
  );
};

export default Header;
