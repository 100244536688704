import { Configuration, ResponseContext, LoginApi, LogoutApi, RefreshApi, UserApi, PasswordApi, RegistrationApi } from '@zevind/scouter-api'

import { authorization } from './stores';

const defaultConfiguration = new Configuration({
  credentials: "include",
  basePath: process.env.REACT_APP_BASE_PATH ? process.env.REACT_APP_BASE_PATH : "http://127.0.0.1:8000",
  middleware: [
    {
      post: async (context: ResponseContext) => {
        if (context.response.status === 401) {
          authorization.isAuthorized = false;
        }
        return context.response;
      },
    },
  ],
})

export const loginApi = new LoginApi(defaultConfiguration);
export const logoutApi = new LogoutApi(defaultConfiguration);
export const refreshApi = new RefreshApi(defaultConfiguration);
export const userApi = new UserApi(defaultConfiguration);
export const passwordApi = new PasswordApi(defaultConfiguration);
export const registrationApi = new RegistrationApi(defaultConfiguration);
