import React from "react";
import { Formik, FormikValues, FormikHelpers } from "formik";
import { ZevCard, ZevCardProps } from "../card";
import { Box, LinearProgress, ButtonProps, useTheme } from "@material-ui/core";
import ZevButton from "../button";

export type SubmitHandler<T> = (
  values: T,
  formikHelpers: FormikHelpers<T>
) => void | Promise<any>;

export interface ZevFormCardProps<T> extends ZevCardProps {
  values: T;
  validationSchema?: any;
  showCancel?: boolean;
  cancelLabel?: string;
  onCancel?: () => void;
  submitLabel: string;
  submitButtonProps?: ButtonProps;
  beforeSubmitElements?: any;
  afterSubmitElements?: any;
  onSubmit: SubmitHandler<T>;
}

function ZevFormCard<T extends FormikValues>({
  values,
  validationSchema,
  submitLabel,
  submitButtonProps,
  beforeSubmitElements,
  afterSubmitElements,
  onSubmit,
  children,
  showCancel,
  cancelLabel = "Cancel",
  onCancel,
  ...other
}: ZevFormCardProps<T>): any {
  const theme = useTheme();

  const convertElements = (
    otherProps: any,
    elements?: JSX.Element | JSX.Element[] | undefined
  ): JSX.Element | JSX.Element | any => {
    if (Array.isArray(elements)) {
      elements.map((element, index) => {
        return React.cloneElement(element, { ...element.props, ...otherProps });
      });
    } else if (elements) {
      return React.cloneElement(elements, { ...elements.props, ...otherProps });
    }
  };

  return (
    <Formik<T>
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={(props) => (
        <ZevCard {...other}>
          {children}
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box height={theme.spacing(4)} />
            {convertElements(props, beforeSubmitElements)}
            <Box display="flex" flexDirection="row">
              {showCancel && (
                <ZevButton
                  onClick={() => {
                    onCancel && onCancel();
                  }}
                >
                  {cancelLabel}
                </ZevButton>
              )}
              {showCancel && <Box width={theme.spacing(2)} />}
              <ZevButton
                disabled={props.isSubmitting || !props.dirty || !props.isValid}
                onClick={props.submitForm}
                type="submit"
                {...submitButtonProps}
              >
                {submitLabel}
              </ZevButton>
            </Box>
            {afterSubmitElements && <Box height={theme.spacing(2)} />}
            {convertElements(props, afterSubmitElements)}
            <Box height={theme.spacing(1)} />
            {props.isSubmitting && <LinearProgress />}
          </Box>
        </ZevCard>
      )}
    />
  );
}

export default ZevFormCard;
