import { responsiveFontSizes, createMuiTheme } from "@material-ui/core";

import 'fontsource-roboto'; // Roboto font for material-ui

import './scouter.css';


export const theme = responsiveFontSizes(createMuiTheme({
  typography: {
    h1: {
      fontSize: '75px',
      fontWeight: 500,
    },
    h2: {
      fontSize: '60px',
      fontWeight: 500,
      textTransform: 'allcaps',
    },
    h3: {
      fontSize: '48px'
    }
  },
  palette: {
    type: "dark",
    text: {
      primary: "#ffffff",
      secondary: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-step-400').trim() || "#000000",
    },
    grey: {
      "500": getComputedStyle(document.documentElement).getPropertyValue('--ion-color-step-500').trim() || "#000000",
      "650": getComputedStyle(document.documentElement).getPropertyValue('--ion-color-step-650').trim() || "#000000",
      "750": getComputedStyle(document.documentElement).getPropertyValue('--ion-color-step-750').trim() || "#000000",
      "800": getComputedStyle(document.documentElement).getPropertyValue('--ion-color-step-800').trim() || "#000000",
      "200": getComputedStyle(document.documentElement).getPropertyValue('--ion-color-step-200').trim() || "#000000",
    },
    warning: {
      main: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-danger').trim() || "#000000",
      dark: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-danger-shade').trim() || "#000000",
      light: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-danger-tint').trim() || "#000000",
      contrastText: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-danger-contrast').trim() || "#000000",
    },
    success: {
      main: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success').trim() || "#000000",
      dark: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success-shade').trim() || "#000000",
      light: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success-tint').trim() || "#000000",
      contrastText: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success-contrast').trim() || "#000000",
    },
    info: {
      main: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-info').trim() || "#000000",
      dark: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-info-shade').trim() || "#000000",
      light: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-info-tint').trim() || "#000000",
      contrastText: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-info-contrast').trim() || "#000000",
    },
    primary : {
      main: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary').trim() || "#000000",
      dark: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary-shade').trim() || "#000000",
      light: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary-tint').trim() || "#000000",
      contrastText: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary-contrast').trim() || "#000000",
    },
    secondary: {
      main: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-secondary').trim() || "#000000",
      dark: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-secondary-shade').trim() || "#000000",
      light: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-secondary-tint').trim() || "#000000",
      contrastText: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-secondary-contrast').trim() || "#000000",
    },
    background: {
      default: '#0E0721'
    }
  }
}));
theme.themeName = "Scouter";