import React from "react";
import { useParams, useHistory } from "react-router";

import { registrationApi } from "../../apis";
import { Typography, Button, Box, useTheme, useMediaQuery } from "@material-ui/core";
import { ZevCard } from "../../components/card";
import { IonPage } from "@ionic/react";
import Content from "../../components/content";

export interface EmailConfirmationProps {}

interface Params {
  username: string;
  key: string;
}

const EmailConfirmation: React.SFC<EmailConfirmationProps> = () => {
  const theme = useTheme();
  const history = useHistory();
  const params: Params = useParams<Params>();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <IonPage>
      <Content>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <ZevCard width={matches ? "530px" : "300px"} title="Confirm Registration">
            <Typography color="textSecondary" variant="body2">
              Press the confirm button below if you are {params.username} and
              have registered to access Zev Industries
            </Typography>
            <Box height={theme.spacing(2)} />
            <Button
              variant="outlined"
              onClick={() => {
                registrationApi
                  .registrationVerifyEmailCreate({
                    data: {
                      key: params.key,
                    },
                  })
                  .then(() => {
                    history.replace("/login");
                  })
                  .catch(() => {
                    // Should we pop up an error at this point???
                  });
              }}
            >
              Confirm
            </Button>
          </ZevCard>
        </Box>
      </Content>
    </IonPage>
  );
};

export default EmailConfirmation;
