import React from "react";
import { Avatar, Box, Typography, useTheme } from "@material-ui/core";

import { user } from "../../stores";
import { ZevCard } from "../card";
import { observer } from "mobx-react";

export interface ProfileProps {}

export const ProfileCard: React.SFC<ProfileProps> = observer(() => {
  const theme = useTheme();

  return (
    <ZevCard>
      <Box display="flex" flexDirection="row">
        <Avatar
          src={user.picture}
          style={{
            width: "80px",
            height: "80px",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: `${theme.palette.secondary.main}`,
          }}
        />
        <Box marginLeft={`${theme.spacing(2)}px`} display="flex" flexDirection="column">
          <Typography color="primary" variant="h5">
            {user.fullName}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {`Last Session: ${user.lastSession}`}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {`Total Time: ${user.totalTime}`}
          </Typography>
        </Box>
      </Box>
    </ZevCard>
  );
});

export default ProfileCard;
