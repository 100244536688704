import React, { FC } from 'react';
import { Button, ButtonProps, useTheme } from '@material-ui/core';

export interface ZevButtonProps extends ButtonProps {
  children?: any;
}

export const ZevButton: FC<ZevButtonProps> = ({children, ...other}: ZevButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      style={{
        paddingLeft: `${theme.spacing(12)}px`,
        paddingRight: `${theme.spacing(12)}px`,
      }}
      variant="outlined"
      color="primary"
      {...other}
    >
      {children}
    </Button>
  );
}

export default ZevButton;