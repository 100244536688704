import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  setupConfig,
  createAnimation,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Login from "./pages/login";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import theme from "./theme";

import { ThemeProvider } from "@material-ui/core";
import Home from "./pages/home";
import { authorization } from "./stores";
import { observer } from "mobx-react";
import Registration from "./pages/registration/Registration";
import EmailConfirmation from "./pages/emailconfirmation/EmailConfirmation";
import PasswordReset from "./pages/passwordreset/PasswordReset";
import PasswordResetConfirm from "./pages/passwordresetconfirm/PasswordResetConfirm";

const getIonPageElement = (element: HTMLElement) => {
  if (element.classList.contains("ion-page")) {
    return element;
  }

  const ionPage = element.querySelector(
    ":scope > .ion-page, :scope > ion-nav, :scope > ion-tabs"
  );
  if (ionPage) {
    return ionPage;
  }
  // idk, return the original element so at least something animates
  // and we don't have a null pointer
  return element;
};

const builder = (baseEl: any, opts?: any): any => {
  const rootTransition = createAnimation()
    .duration(300)
    .easing("cubic-bezier(0.3,0,0.66,1)");

  // ensure that the entering page is visible from the start of the transition
  const enteringPage = createAnimation()
    .addElement(getIonPageElement(opts.enteringEl))
    .beforeRemoveClass("ion-page-invisible");

  // create animation for the leaving page
  const leavingPage = createAnimation().addElement(
    getIonPageElement(opts.leavingEl)
  );

  enteringPage.fromTo("opacity", "0.25", "1");
  leavingPage.fromTo("opacity", "1", "0.25");

  // include animations for both pages into the root animation
  rootTransition.addAnimation(enteringPage);
  rootTransition.addAnimation(leavingPage);
  return rootTransition;
};

setupConfig({
  navAnimation: builder,
});

const NO_AUTH_ROUTES = ["/email-confirmation", "/registration", "/password-reset"];

const AuthObserver: React.FC = observer(({ children }) => {
  const history = useHistory();

  const isAuthorized = authorization.isAuthorized;

  useEffect(() => {
    let requiresAuth = true;

    // Determine if the route we are going to requires authentication.
    for (let i = 0; i < NO_AUTH_ROUTES.length; i++) {
      if (history.location.pathname.startsWith(NO_AUTH_ROUTES[i])) {
        requiresAuth = false;
        break;
      }
    }

    if (requiresAuth) {
      if (!isAuthorized) {
        history.replace("/login");
      } else {
        history.replace("/home");
      }
    }
  }, [isAuthorized, history]);

  return <>{children}</>;
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <IonApp>
        <IonReactRouter>
          <AuthObserver>
            <IonRouterOutlet>
              <Route path="/home" component={Home} />
              <Route path="/login" component={Login} />
              <Route
                path={`/email-confirmation/:username/:key`}
                component={EmailConfirmation}
              />
              <Route
                path={`/registration`}
                component={Registration}
                exact={true}
              />
              <Route
                exact
                path={`/password-reset`}
                component={PasswordReset}
              />
              <Route
                path={`/password-reset/:username/:uid/:token`}
                component={PasswordResetConfirm}
              />
            </IonRouterOutlet>
          </AuthObserver>
        </IonReactRouter>
      </IonApp>
    </ThemeProvider>
  );
};

export default App;
