import { observable } from "mobx";

class Authorization {
  @observable isAuthorized = false;
}

export var authorization = new Authorization();

export default authorization;



