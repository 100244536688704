import React, { FC } from 'react';
import { styled, Theme, withTheme } from '@material-ui/core/styles';
import { IonContent } from '@ionic/react';

import background from './background_image.png';


export interface ContentProps {
  useBackgroundImage?: boolean;
  padding?: boolean
  children?: any;
}

export interface StyledContentProps extends ContentProps {
  theme?: Theme;
}


const StyledContent = withTheme(styled(({ useBackgroundImage, theme, ...other}: StyledContentProps) => <IonContent {...other}/>)({
  "--background": (props: StyledContentProps) => props.useBackgroundImage ? `url(${background}) left top/cover` : props.theme?.palette.background.default,
  "--color": (props: StyledContentProps) => props.theme?.palette.grey[500],
  "--padding-start": (props: StyledContentProps) => props.padding ? `${props.theme?.spacing(2)}px` : '0',
  "--padding-end": (props: StyledContentProps) => props.padding ? `${props.theme?.spacing(2)}px` : '0',
  "--padding-top": (props: StyledContentProps) => props.padding ? `${props.theme?.spacing(2)}px` : '0',
  "--padding-bottom": (props: StyledContentProps) => props.padding ? `${props.theme?.spacing(2)}px` : '0',
}));

export const Content: FC<ContentProps> = ( {
  useBackgroundImage=false,
  padding=false,
  children}: ContentProps ) => {
  return (
    <StyledContent padding={padding} useBackgroundImage={useBackgroundImage}>
      {children}
    </StyledContent>
  )
};


export default Content;