import { IonPage, useIonViewWillEnter } from "@ionic/react";
import React from "react";

import { loginApi } from "../../apis";
import { refreshUser } from "../../stores/User";

import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginProps,
} from "react-google-login";

import Content from "../../components/content";
import Logo from "../../components/logo";
import TextField from "../../components/textfield";
import { Field, FormikState } from "formik";

import * as yup from "yup";
import { authorization } from "../../stores";
import ZevFormCard, { SubmitHandler } from "../../components/formcard/FormCard";
import { Box, Link, useMediaQuery, useTheme } from "@material-ui/core";

interface Values {
  username: string;
  password: string;
}

const LoginSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

export interface ZevGoogleLoginProps extends GoogleLoginProps {
  resetForm?: (nextState?: Partial<FormikState<Values>>) => void;
}

const ZevGoogleLogin: React.SFC<ZevGoogleLoginProps> = (
  props: ZevGoogleLoginProps
) => {
  return (
    <GoogleLogin
      onRequest={() => {
        if (props.resetForm) {
          props.resetForm();
        }
      }}
      {...props}
    />
  );
};

const Login: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  useIonViewWillEnter(async () => {
    if (await refreshUser()) {
      authorization.isAuthorized = true;
    }
  });

  const onLogin: SubmitHandler<Values> = (
    values,
    { resetForm, setSubmitting, setFieldError }
  ) => {
    loginApi
      .loginCreate({
        data: {
          username: values.username,
          password: values.password,
        },
      })
      .then((response) => {
        resetForm();
        refreshUser().then((value) => {
          if (value) {
            authorization.isAuthorized = true;
          }
        });
      })
      .catch((response: any) => {
        try {
          response.json().then((value: any) => {
            setFieldError("password", value.non_field_errors);
          });
        } catch {
          setFieldError("password", "Network error");
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <IonPage>
      <Content useBackgroundImage>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box height={matches ? "140px" : "60px"} />
          <Logo width={matches ? "300px" : "200px"} />
          <Box height={matches ? "140px" : "60px"} />
          <ZevFormCard
            title="Login"
            validationSchema={LoginSchema}
            width={matches ? "530px" : "300px"}
            submitLabel="Login"
            submitButtonProps={{
              variant: "outlined",
            }}
            afterSubmitElements={
              <ZevGoogleLogin
                theme="dark"
                clientId="716678886202-osdjos44ed6te9f8rvm9t3gib2oqduqs.apps.googleusercontent.com"
                responseType="token"
                onSuccess={(response) => {
                  loginApi
                    .loginGoogleCreate({
                      data: {
                        accessToken: (response as GoogleLoginResponse)
                          .accessToken,
                      },
                    })
                    .then((value) => {
                      authorization.isAuthorized = true;
                    });
                }}
                onFailure={(response) => {
                  console.log(response);
                }}
              />
            }
            onSubmit={onLogin}
            values={{ username: "", password: "" }}
            startActions={
              <Link underline="none" href="/password-reset">
                Forgot password
              </Link>
            }
            endActions={
              <Link underline="none" href="/registration">
                Need account? Sign up
              </Link>
            }
          >
            <Field
              component={TextField}
              fullWidth
              type="username"
              label="Username"
              name="username"
              required
            />
            <Field
              component={TextField}
              fullWidth
              type="password"
              label="Password"
              name="password"
              required
            />
          </ZevFormCard>
        </Box>
      </Content>
    </IonPage>
  );
};

export default Login;
