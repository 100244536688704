import React, { useState } from "react";
import ZevFormCard, { SubmitHandler } from "../../components/formcard/FormCard";

import * as yup from "yup";
import {
  Box,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Field } from "formik";
import TextField from "../../components/textfield";
import { registrationApi } from "../../apis";
import { IonPage } from "@ionic/react";
import Content from "../../components/content";
import { useHistory } from "react-router";

interface Values {
  username: string;
  email: string;
  password1: string;
  password2: string;
}

const RegisterSchema = yup.object().shape({
  username: yup.string().required(),
  email: yup
    .string()
    .required()
    .email(),
  password1: yup.string().required(),
  password2: yup
    .string()
    .required()
    .test("passwords-match", "Passwords must match", function(value) {
      return this.parent.password1 === value;
    }),
});

const Registration: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onRegister: SubmitHandler<Values> = (
    values,
    { resetForm, setSubmitting, setFieldError }
  ) => {
    registrationApi
      .registrationCreate({
        data: {
          username: values.username,
          email: values.email,
          password1: values.password1,
          password2: values.password2,
        },
      })
      .then(() => {
        resetForm();
        setDialogOpen(true);
      })
      .catch((response) => {
        response
          .json()
          .then((value: any) => {
            console.log(value);
            if (value.username) {
              setFieldError("username", value.username);
            } else if (value.email) {
              setFieldError("email", value.email);
            } else if (value.password1) {
              setFieldError("password1", value.password1);
            }
          })
          .catch(() => {
            setFieldError("password2", "Network issue");
          });

        console.log(response);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <IonPage>
      <Content>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <ZevFormCard
            title="Zev Industries Signup"
            validationSchema={RegisterSchema}
            width={matches ? "530px" : "300px"}
            showCancel
            onCancel={() => {history.goBack()}}
            submitLabel="Signup"
            submitButtonProps={{
              variant: "outlined",
            }}
            onSubmit={onRegister}
            values={{ username: "", email: "", password1: "", password2: "" }}
          >
            <Field
              component={TextField}
              fullWidth
              type="username"
              label="Username"
              name="username"
              required
            />
            <Field
              component={TextField}
              fullWidth
              type="email"
              label="Email"
              name="email"
              required
            />
            <Field
              component={TextField}
              fullWidth
              type="password"
              label="Password"
              name="password1"
              required
            />
            <Field
              component={TextField}
              fullWidth
              type="password"
              label="Confirm Password"
              name="password2"
              required
            />
          </ZevFormCard>
        </Box>
        <Dialog open={dialogOpen}>
          <DialogTitle>Registration Sent</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your registration has been sent. Please check your email for a
              link to confirm your email.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
                history.replace('/login');
              }}
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Content>
    </IonPage>
  );
};

export default Registration;
